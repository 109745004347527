import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/common/layout/layout"
import styled from "styled-components";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SEO from "../components/common/layout/seo";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

export default function Template({location, data}) {
    const post = data.contentfulBlogPost;
    const Text = ({ children }) => (
        <div><p>{children}</p><br/></div>
    )

    const renderOptions = {
        renderMark: {
            [MARKS.BOLD]: (text) => <span className="font-bold">{text}</span>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [INLINES.HYPERLINK]: (node, children) => {
                return <span className="text-purple hover:underline underline-offset-2 hover:cursor-pointer">{children}</span>;
            },
            [BLOCKS.HEADING_2]: (node, children) => {
                return <div className="py-2"><h2>{children}</h2></div>;
            },
            [BLOCKS.UL_LIST]: (node, children) => (
                <ul>{children}</ul>
            ),
            [BLOCKS.OL_LIST]: (node, children) => (
                <ol>{children}</ol>
            ),
            [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
        },
    }
    return (
        <div>
            <Layout location={location}>
                <MiddleContainer>
                    <SEO
                        title={post.title}
                        pageImage={post.heroImage}

                    />
                    <BlogGroup>
                            <div className="flex flex-col py-5 items-center">
                                <Title className="self-center">{post.title}</Title>
                                <div className="text-slate-400 pb-10 ">{post.publishDate}</div>
                                {post.heroImage && (
                                <GatsbyImage className="place-self-center m-auto mb-2" image={getImage(post.heroImage)} alt={post.heroImage.title} />

                                )}

                                <div className="text-slate-800 py-10 max-w-prose">{documentToReactComponents(JSON.parse(post.richBody.raw), renderOptions)}</div>
                            </div>
                    </BlogGroup>
                </MiddleContainer>
            </Layout>
        </div>
    )
}

const BlogGroup = styled.div`

//padding: 160px 20px 10px 60px;
  padding-top:160px;
  padding-bottom:10px;
  margin: 0 auto;
  //padding: 0 16px;
  @media (max-width: ${props => props.theme.screen.md}) {
  }
`
export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
    slug
    title
    author {
      name
    }
    publishDate(formatString: "MMMM Do, YYYY")
    rawDate: publishDate
    heroImage {
                  publicUrl
                  title
                  
                  
                  gatsbyImageData(
                    width: 750
                    placeholder: BLURRED
                  )
                 
    }
    richBody {
        raw
    }
    tags
    description {
      description
    }
  }
  }
`
const MiddleContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  @media (min-width: ${props => props.theme.screen.xs}) {
    max-width: 540px;
  }
  @media (min-width: ${props => props.theme.screen.sm}) {
    max-width: 720px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    max-width: 960px;
  }
  @media (min-width: ${props => props.theme.screen.lg}) {
    max-width: 1200px;
  }
`
const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #121640;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 32px;
  }
`
